import { SliceZone } from '@prismicio/react';
import { Layout, SEO, sliceComponents } from 'components';
import { graphql } from 'gatsby';
import * as React from 'react';

interface IBlogPageTemplateProps {
  data: any;
}

const BlogPageTemplate: React.FunctionComponent<IBlogPageTemplateProps> = ({ data }) => {

  if (!data) {
    return null;
  }

  const doc = data.prismicBlogPage.data;

  return (
    <Layout>
      <SEO
        title={
          doc.body1?.[0]?.primary?.title || doc.body[0].primary.blog_title.text
        }
        description={
          doc.body1?.[0]?.primary?.description
        }
      />
      <SliceZone
        slices={doc.body}
        context={{
          published_at: doc.published_at,
        }}
        components={sliceComponents}
        defaultComponent={() => null} />
    </Layout>
  );
};

export const query = graphql`
  query MyBlogQuery($uid: String) {
    prismicBlogPage(uid: {eq: $uid}) {
      data {
        published_at
        body1 {
          ... on PrismicBlogPageDataBody1GeneralCard {
            slice_type
            primary {
              description
              title
            }
          }
        }
        body {
          ... on PrismicBlogPageDataBodyBlog {
            slice_type
            primary {
              author_description
              author_name
              blog_category
              blog_content {
                richText
              }
              blog_title {
                richText
                text
              }
              feature_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPageTemplate;
